<template>
    <div class="photograph_detail_info">
        <el-row :gutter="20">
            <!-- 图片列表列 -->
            <el-col :lg="11" :md="12" ref="imgList">
                <div class="detail_images_box">
                    <!-- 小图列表-->
                    <img-list
                        :md-img="mdImg"
                        :img-list="imgs"
                        @change-md="handleMdChange">
                    </img-list>
                    <!-- 详情说明 -->
                    <div class="photo_detail_explain">
                        <!-- 标题区域 -->
                        <div class="photo_detail_explain_title">
                            <span>服务介绍 / Service Introduction</span>
                        </div>
                        <!-- 内容区域 -->
                        <div class="photo_detail_explain_content">
                            <div v-html="info.intro"></div>
                        </div>
                    </div>
                </div>
            </el-col>
            <!-- 选项表单部分 -->
            <el-col :lg="13" :md="12">
                <div class="detail_form_box">
                    <div class="detail_form_title">{{info.title}}</div>
                    <el-tag 
                        type="danger" 
                        class="detail-form-error"
                        v-if="is_login && info.allow_order == 0">
                        未通过学生认证，不可下单！
                    </el-tag>
                    <el-form 
                        label-width="180px"
                        label-position="left"
                        :model="form"
                        :rules="rules"
                        ref="addForm">
                        <!-- 拍摄价格 -->
                        <el-form-item 
                            label="价格 / Price">
                            <div class="detail_form_price">
                                <span>¥</span>
                                <span>{{info.price}}</span>
                                <span class="detail_form_price_unit">{{info.unit}}</span>
                            </div>
                        </el-form-item>
                        <!-- 拍摄风格 -->
                        <el-form-item 
                            label="拍摄风格 / Style"
                            prop="style_id">
                            <ul class="detail_form_select_list">
                                <!-- 选中样式 class="active" -->
                                <li v-for="(item,index) in info.style"
                                    :key="index"
                                    :class="{active:form.style_id===item.id}">
                                    <a href="javascript:;" @click="selectStyle(item.id)">{{item.name}}</a>
                                </li>
                            </ul>
                        </el-form-item>
                        <!-- 拍摄日期 -->
                        <el-form-item 
                            label="拍摄日期 / Date"
                            prop="dateValue">
                            <el-date-picker
                                v-model="form.dateValue"
                                type="date"
                                format="yyyy-MM-dd"
                                :picker-options="pickerOptions"
                                value-format="yyyy-MM-dd"
                                @change="handleDate">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 拍摄时间 -->
                        <el-form-item 
                            label="拍摄时间 / Time"
                            prop="startTime">
                            <div class="detail_form_select_time">
                                <el-time-select
                                    palceholder="开始时间"
                                    v-model="form.startTime"
                                    :picker-options="{
                                        start: info.open_begin,
                                        step: '01:00',
                                        end: info.open_end,
                                        minTime: time_picker,
                                    }">
                                </el-time-select>
                            </div>
                        </el-form-item>
                        <!-- 拍摄场地 -->
                        <el-form-item 
                            label="拍摄场地 / Place"
                            prop="space_id" v-if="info.field">
                            <el-cascader
                                :options="info.field"
                                v-model="form.space_id"
                                :show-all-levels="false"
                                @change="handleCascader">
                            </el-cascader>
                        </el-form-item>
                        <!-- 胶卷选择 -->
                        <el-form-item 
                            label="套餐 / Package"
                            prop="film_type">
                            <ul class="detail_form_select_list">
                                <li v-for="(item,index) in info.film"
                                    :key="index"
                                    :class="{active:form.film_type===item.id}">
                                    <a href="javascript:;" @click="selectFilm(item.id)">{{item.name}}</a>
                                </li>
                            </ul>
                        </el-form-item>
                        <!-- 化妆师 -->
                        <el-form-item 
                            label="化妆师 / Makeup Aritist"
                            prop="need_dresser">
                            <ul class="detail_form_select_list">
                                <li :class="{active:form.need_dresser==='1'}">
                                    <a href="javascript:;" @click="selectDresser('1')">需要</a>
                                </li>
                                <li :class="{active:form.need_dresser==='0'}">
                                    <a href="javascript:;" @click="selectDresser('0')">不需要</a>
                                </li>
                            </ul>
                        </el-form-item>
                        <!-- 拍摄人数 -->
                        <el-form-item 
                            label="拍摄人数 / Number"
                            prop="photography_number">
                            <el-input-number 
                                v-model="form.photography_number" 
                                :min="1"
                                :max="info.max_number"
                                @change="handleNum">
                            </el-input-number>
                        </el-form-item>
                        <!-- 按钮 -->
                        <el-form-item>
                            <el-button 
                                type="warning" 
                                class="add_order"
                                @click="submitOrder('addForm')">立即下单</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <detail-tabs
                    :detail-content="info.description">
                </detail-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        let style_id = sessionStorage.getItem("style_id") || "",
        booking_begin = sessionStorage.getItem("booking_begin") || "",
        space_id = JSON.parse(sessionStorage.getItem("space_id")) || "",
        film_type = sessionStorage.getItem("film_type") || "",
        need_dresser = sessionStorage.getItem("need_dresser") || "",
        photography_number = sessionStorage.getItem("photography_number") || "";
        return {
            form: {
                style_id: style_id || "", // 拍摄风格选择
                space_id: space_id || [], // 拍摄场地
                need_dresser: need_dresser || "", // 化妆师
                film_type: film_type || "", // 胶卷选择
                photography_number: photography_number|| 1, // 拍摄人数
                dateValue: booking_begin || "", // 拍摄日期绑定数据
                startTime: "" // 拍摄开始时间绑定数据
            },
            rules: {
                style_id: [
                    { required: true, message: '请选择风格', trigger: 'blur' },
                ],
                space_id: [
                    { required: true, message: '请选择场地', trigger: 'blur' },
                ],
                need_dresser: [
                    { required: true, message: '请选择是否需要化妆师', trigger: 'blur' },
                ],
                film_type: [
                    { required: true, message: '请选择胶卷', trigger: 'blur' },
                ],
                photography_number: [
                    { required: true, message: '请选择人数', trigger: 'blur' },
                ],
                dateValue: [
                    { required: true, message: '请选择日期', trigger: 'blur' },
                ],
                startTime: [
                    { required: true, message: '请选择时间', trigger: 'blur' },
                ]
            },
            pickerOptions: {
                disabledDate:(time)=>{
                    return time.getTime() < Date.now();
                }
            },
        }
    },
    computed: mapState({
        info: state => state.goodsDetail.info,
        is_login: state => state.user.is_login,
        user_type: state => state.user.user_type,
        mdImg: state => state.goodsDetail.mdImg,
        imgs: state => state.goodsDetail.imgs,
        time_picker(){
            let now_time = new Date();
            let selected_time = new Date(this.form.dateValue);
            let today = new Date(new Date().setHours(0, 0, 0, 0));
            let tomorrow = today.setDate(today.getDate()+2); // 时间戳

            let afterTwo = new Date(now_time.getTime()), // 当前时间往后推两小时（根据需求修改为去掉往后推的两小时）
            after_hours = afterTwo.getHours(), 
            after_minutes = afterTwo.getMinutes(),
            h = after_hours < 10? ('0' + after_hours): after_hours,
            m = after_minutes < 10? ('0' + after_minutes): after_minutes;

            let begin_arr = [], end_arr = [];
            if(this.info.open_begin) begin_arr = this.info.open_begin.split(":"); // 服务器返回开始时间
            if(this.info.open_end) end_arr = this.info.open_end.split(":"); // 服务器返回结束时间

            let param_time = ""; // 返回参数
            
            if(selected_time.getTime() < tomorrow){ // 今天
                // 
                if(after_hours < begin_arr[0])  
                {
                    param_time = begin_arr[0] - 1 + ":" + begin_arr[1];
                }
                if(after_hours > end_arr[0])  
                {
                    param_time = this.info.open_end;
                }
                if(after_hours >= begin_arr[0] && after_hours <= end_arr[0]) {
                    param_time = h + ":" + m;
                }
            }else{ // 第二天及之后
                param_time = begin_arr[0] - 1 + ":" + begin_arr[1];
            }
            return param_time;
        }
    }),
    components:{
        imgList: () => import('./detailImgList'),
        detailTabs: ()=> import('@/components/detailTabs'),
    },
    methods: {
        ...mapMutations([
            "changeMdImg", // 更改中图片api
        ]),
        ...mapActions([
            "handlePhotograpAddOrder",
            "getPhotographDetail"
        ]),
        handleMdChange(md,index) {
            this.changeMdImg({
                md,
                index
            });
        },
        handleGet(){ // 发起当前商品请求
            let form = this.form;
            this.getPhotographDetail({
                theme_id: this.$route.query.id,
                style_id: form.style_id || "",
                space_id: form.space_id[form.space_id.length-1] || "",
                film_type: form.film_type,
                need_dresser: form.need_dresser,
                photography_number: form.photography_number
            })
        },
        // 拍摄风格选择
        selectStyle(arg){
            this.form.style_id = arg;
            sessionStorage.setItem("style_id", arg);
            this.handleGet();
        },
        handleDate(arg){ // 日期
            let value = arg === null? "": arg;
            sessionStorage.setItem("booking_begin", value);
            this.form.startTime = "";
        },
        handleCascader(arg){ // 地区选择
            let space_id = arg[arg.length-1];
            sessionStorage.setItem("space_id", JSON.stringify(arg));
            this.handleGet();
        },
        // 胶卷选择
        selectFilm(arg){
            this.form.film_type = arg;
            sessionStorage.setItem("film_type", arg);
            this.handleGet();
        },
        selectDresser(arg){ // 是否需要化妆师
            this.form.need_dresser = arg;
            sessionStorage.setItem("need_dresser", arg);
            this.handleGet();
        },
        handleNum(){ // 计数器
            this.handleGet();
        },
        validate(){ // 验证函数
            if(this.activeStyle == ""){
                return false
            }
        },
        submitOrder(formName){ // 提交订单
            if(this.is_login){
                if(this.info.allow_order == 0){
                    // this.$confirm('您还未通过校园认证',{
                    //     confirmButtonText: "立即认证",
                    //     cancelButtonText: "取消",
                    //     type: 'warning',
                    //     center: true
                    // }).then(() => {
                    //     this.$router.push("/center/campus")
                    // }).catch(() => {})
                    return;
                }
                let booking_begin = this.form.dateValue + " " + this.form.startTime;
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.handlePhotograpAddOrder({
                            price: this.info.price,
                            theme_id: this.$route.query.id,
                            style_id: this.form.style_id,
                            space_id: this.form.space_id[this.form.space_id.length-1],
                            need_dresser: this.form.need_dresser,
                            film_type: this.form.film_type,
                            photography_number: this.form.photography_number,
                            booking_begin
                        }).then((res) => {
                            if(res.type == "success"){
                                this.$router.push({
                                    name: "photographPay",
                                    query: {
                                        id: res.msg
                                    }
                                })
                            }
                        })
                    } else {
                        return false;
                    }
                });
            }else{
                this.$message({
                    showClose: true,
                    message: "您还未登录！",
                    type: "warning"
                })
            }
        }
    },
    watch: {
        info(val){
            if(!val.goods_id) return;
            let style_id = sessionStorage.getItem("style_id") || -1;
            if(style_id == -1){
                val.style.map((item, index, arr) => {
                    if(index == 0){
                        this.selectStyle(item.id)
                    }
                })
            }
        }
    },
}
</script>

<style scoped>
/* 预约拍照详情盒子 */
.photograph_detail_info{
    margin-bottom: 50px;
}

/* 图片列表区域盒子 */
.detail_form_box{
    padding: 20px;
}
/* 错误提示 */
.detail-form-error{
    margin-bottom: 10px;
}
/* 表单标题 */
.detail_form_title{
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
}
.photo_detail_explain{
    margin-top: 20px;
    background: #fff;
    border-radius: 10px;
}

/* 详细说明区域 */
/* 标题 */
.photo_detail_explain_title>span{
    display: inline-block;
    padding: 5px 20px;
    font-size: 17px;
    color: #303133;
}
/* 内容 */
.photo_detail_explain_content{
    padding: 20px;
    color: #909399;
}

/* 表单区域 */
.detail_form_box>>>.el-form-item__label{
    font-weight: 100;
    color: #909399
}
.detail_form_box>>>.el-form-item__label:before{
    color: #8d9afc !important;
}
/* 价格 */
/* .detail_form_price{
    color: #E6A23C;
} */
.detail_form_price>span{
    margin-left: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #E6A23C;
}
.detail_form_price>.detail_form_price_unit{
    font-size: 15px;
    color: #909399;
}
/* 选项列表 */
.detail_form_select_list>li{
    float: left;
    margin-right: 10px;
    border-radius: 20px;
}
.detail_form_select_list>li>a{
    display: inline-block;
    padding: 0 20px;
    color: #606266;
}
/* 选项列表选中时状态 */
.detail_form_select_list>li.active{
    background-color: #E6A23C;
}
.detail_form_select_list>li.active>a{
    color: #fff;
}
/* 拍摄时间区域样式 */
.detail_form_select_time{
    width: 100%;
}
/* .detail_form_select_time>.el-date-editor{
    width: 130px;
} */
/* 立即下单按钮 */
.add_order{
    width: 200px;
    height: 50px;
}
</style>
